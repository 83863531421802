.ce_rsce_slideshowStart {
    height: 100vh;
    .container {
        position: relative;
        &.swiper-wrapper {
            height: 100vh;
            .swiper-slide {
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-color: rgba(0,0,0,0.5);
                }
            }
        }
    }
    .container.text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .button {
            width: 100%;
            margin: 0 auto;
            background-color: $primary;
            color: $white;
        }
        .container-inner.text {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%,-50%,0);
            z-index: 100;
            text-align: center;
            h2, h6 {
                color: $white;
            }
        }
    }
    .swiper-button-next:after, .swiper-button-prev:after {
        padding: .5rem;
        background-color: $white;
        color: $primary;
    }
    .mouse {
        position: absolute;
        left: 50%;
        transform: translateX(-25%);
        bottom: 4rem;
        width: 64px;
        height: 64px;
        z-index: 1;
        animation: 3s infinite mousemove;
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-image: url('/files/themes/alte-post/images/mouse.png');
            background-repeat: no-repeat;
        }
    }
}


@mixin slideshowstart_tablet {
    .ce_rsce_slideshowStart {
        .container.text {
            .button {
                width: 60%;
            }
        }
    }
}



@keyframes mousemove {
    0%{
        opacity: 0;
        bottom: 6rem;
    }
    50% {
        opacity: 1;
        bottom: 4rem;
    }
    100%{
        opacity: 0;
        bottom: 2rem;
    }
}