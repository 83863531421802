.ce_rsce_textZweispaltig {
    padding: $paddingMobile;
    .container {
        display: flex;
        flex-wrap: wrap;
        gap: 5rem;
        .container-inner {
            flex: calc(50% - 5rem);
            width: calc(50% - 5rem);
            min-width: 300px;
            img {
                margin-bottom: 2rem;
                width: 100%;
                max-height: 350px;
                object-fit: cover;
            }
        }
    }
}

@mixin rsce_textZweispaltig_tablet {
    .ce_rsce_textZweispaltig {
        padding: $paddingDesktop;
        .container {
            .container-inner {

            }
        }
    }
}