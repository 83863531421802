.ce_rsce_angebotUebersicht {
    padding: $paddingMobile;
    .container {
        .container-inner {
           max-width: 50%;
           min-width: 250px;
        }
        .mod_navigation {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            position: relative;
            &::after {
                display: none;
                content: "";
                position: absolute;
                right: 0;
                top: -350px;
                max-width: 500px;
                width: 100%;
                height: 350px;
                background-image: url('/files/themes/alte-post/images/kutsche.png');
                background-size: cover;
                background-repeat: no-repeat;
                opacity: .3;
            }
            .angebot {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 1rem;
                &:nth-child(even){
                    background-color: $background;
                }
                .angebot-inner {
                    flex: calc(50% - 2rem);
                    width: calc(50% - 2rem);
                    min-width: 250px;
                    &:last-of-type {
                        padding: 2rem;
                    }
                    h3 {
                        margin-bottom: .5rem;
                    }
                    .date {
                        font-weight: bold;
                        margin: 0;
                    }
                    .preis {
                        text-align: right;
                        color: $black;
                        font-size: 1.5rem;
                        font-weight: bold;
                    }
                    ul {
                        margin-left: 0;
                        padding-left: 1rem;
                    }
                    img {
                        width: 100%;
                    }
                    .button {
                        display: block;
                        text-align: center;
                        width: 100%;
                    }
                }
            }
        }
    }
}



@mixin rsce_angebotUebersicht_tablet {
    .ce_rsce_angebotUebersicht {
        padding: $paddingDesktop;
        .container {
            .container-inner {
    
            }
            .mod_navigation {
                &::after {
                    display: block;
                }
                .angebot {
                    gap: 2rem;
                }
            }
        }
    }
}