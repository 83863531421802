.ce_rsce_header {
    z-index: 1000;
    padding: 2rem 2rem;
    background-color: $primary;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    transition: height .5s;
    &.scrolled {
        height: 55px;
        .container {
            .container-inner.logo {
                width: 100px;
                height: 75px;
            }
        }
    }
    .container {
        position: relative;
        height: 100%;
        .container-inner.menu {
            position: absolute;
            left: 0;
            height: 100%;
            display: flex;
            align-items: center;
            z-index: 10000000;
            &.open {
                .menuButton {
                    .line {
                        opacity: 0;
                        background-color: $primary;
                    }
                    .line:first-of-type {
                        position: absolute;
                        opacity: 1;
                        top: 50%;
                        transform: rotateZ(-45deg);
                    }
                    .line:last-of-type {
                        position: absolute;
                        opacity: 1;
                        top: 50%;
                        transform: rotateZ(45deg);
                    }
                }
            }
            .menuButton {
                position: relative;
                .line {
                    background-color: $white;
                    width: 40px;
                    height: 2px;
                    display: block;
                    transition: transform .5s;
                }
                .line:first-of-type {
                    position: relative;
                    top: -.5rem;
                }
                .line:last-of-type {
                    position: relative;
                    top: .5rem;
                }
                .menuText {
                    position: absolute;
                    right: -4rem;
                    top: -9px;
                    span {
                        font-size: 1.25rem;
                        color: $white;
                    }
                }
            }
            
            &:hover {
                cursor: pointer;
            }
        }
        .container-inner.logo {
            position: absolute;
            top: -2rem;
            left: 50%;
            transform: translateX(-50%);
            width: 100px;
            height: 75px;
            background-color: $white;
            padding: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .5s;
            a {
                width: 100%;
            }
        }
        .container-inner.contact {
            position: absolute;
            right: 0;
            height: 100%;
            display: flex;
            align-items: center;
            .container-inner {
                display: flex;
                gap: 2rem;
                .link {
                    display: none;
                    gap: 1rem;
                    color: $white;
                    align-items: center;
                    img {
                        max-width: 15px;
                    }
                }
            }
        }
    }
    .container.navigation {
        display: block ;
        position: fixed;
        left: -100%;
        top: 0;
        height: 100vh;
        width: 80%;
        min-width: 200px;
        max-width: 500px;
        transition: left .5s;
        
        &.open {
            display: block;
            left: 0;
            background-color: $white;
            border-right: 1px solid $primary;
        }
        .mod_navigation {
            padding: 6rem 2rem 2rem 2rem;
            border-bottom: 1px solid $primary;
            ul {
                padding-left: 0;
                margin: 0;
                li {
                    list-style: none;
                    margin-bottom: .5rem;
                    letter-spacing: .05em;
                    &:hover {
                        cursor: pointer;
                    }
                    a {
                        font-size: 1.25rem;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            strong.active, strong.forward {
                color: $primary;
                font-weight: bold;
                font-size: 1.25rem;
            }
            li.submenu.open {
                &::after {
                    transform: rotateZ(90deg);
                }
                .level_2 {
                    display: block;
                }
            }
            .level_1{

                li.submenu {
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 4px;
                        background-image: url('/files/themes/alte-post/images/open.png');
                        background-size: cover;
                        width: 15px;
                        height: 15px;
                        transition: transform .5s;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
                .level_2 {
                    margin-top: 1rem;
                    margin-bottom: 2rem;
                    margin-left: 1rem;
                    display: none;
                }
            }
        }

        .ce_rsce_contactIcon {
            padding: 2rem;
            border-bottom: 1px solid $primary;
            .icon {
                margin-bottom: 1rem;
                .link {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}




@mixin rsce_header_tablet {
    .ce_rsce_header {
        padding: 0rem 6rem;
        &.scrolled {
            .container {
                .container-inner.logo {
                    top: 0;
                    width: 150px;
                    height: 100px;
                }
            }
        }
        .container {
            .container-inner.contact {
                .icon {
                    .link {
                        display: none;
                    }
                }
            }
            .container-inner {
                
            }  
            .container-inner.logo {
                top: 0;
                width: 200px;
                height: 150px;
            }
        }
    }
}

@mixin rsce_header_tablet_l {
    .ce_rsce_header {
        padding: 0rem 6rem;
        .container {
            .container-inner.contact {
                .icon {
                    .link {
                        display: flex;
                    }
                }
            }
            .container-inner {
                
            }  
        }
    }
}