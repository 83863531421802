
// Overwrite Global Variables here
@import "./2_base/4_mixins.scss"; // Import Variables

@import "variables.scss"; // Import Variables


/*! --Plugins & Libraries-- */
@import "./0_typography/main.scss"; // Import Typography Lib after settings


/*
---
name: Gridlex
category: SCSS Libraries
---
Wir benutzen zum layouten das auf flexbox basierende Grid-System Gridlex.

**Beispiel:**
```sample:/styleguide/assets/gridlex-demo.html
```
Nutzung und Möglichkeiten lassen sich in der [Gridlex Dokumentation](http://gridlex.devlint.fr/) nachlesen.

[Dokumentation zu den SCSS Variablen](http://gridlex.devlint.fr/#sass-documentation)

Falls kein Grid-System benötigt wird, einfach auskommentieren.
*/
/*
$gl-colCount: 12;
$gl-gutter: 1rem;
$gl-gutter-vertical: 1rem;
$gl-mq-width: 'max-width';
$gl-mq-list: (
	lg: $desktop,
	md: $tablet, 
	sm: $tablet_s, 
	xs: $mobile 
);
@import "gridlex/src/gridlex.scss";
*/

/*! --Fonts-- */
/*
---
name: Mono Icons
category: SCSS Libraries
---
Wir benutzen [Mono Icons](https://icons.mono.company/).

**Nutzung:** Dem Element, in dem das Icon angezeigt werden soll, die Klasse `.mi-<icon-name-here>` geben

Falls Kein Icon Font benötigt wird, einfach auskommentieren.

```icons:/styleguide/assets/icons.html
```
*/
@import "_lib/mono-icons.scss";

@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/1_plugins/aos.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/1_plugins/lightbox-fatbox.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/1_plugins/swiper.scss";


/*! ---STANDARD--- */
@import "_lib/modern-normalize.scss"; // CSS Reset

/*! ---CUSTOM--- */
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/2_base/0_font.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/2_base/1_typography.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/2_base/2_layout.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/2_base/3_scss-contao-base.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/2_base/4_mixins.scss";
 // Base of the Theme

/*! --Elements-- */
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/ce_text.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_angebotSlider.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_angebotUebersicht.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_counter.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_cta.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_downloads.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_footer.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_formular.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_gallerie.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_gutschein.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_header.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_headerUnterseite.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_infoBlock.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_popup.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_quicklinks.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_slider.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_slideshowStart.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_textBild.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_textZweispaltig.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_trenner.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_wellnesspreise.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/3_elements/rsce_zimmer.scss";
 // Component Styling

@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/4_last/0_breakpoints.scss";
@import "/Users/johannesblochl/Desktop/dev/alte-post-badbirnbach.de/contao/files/themes/alte-post/src/scss/4_last/1_print.scss";
 // Media Queries
