.ce_rsce_headerUnterseite {
    width: 100%;
    max-height: 900px;
    min-height: 450px;
    .container {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.5);
        }
        img {
            width: 100%;
            height: 100%;
            max-height: 900px;
            min-height: 450px;
            object-fit: cover;
        }
        .container-inner {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            z-index: 100;
            text-align: center;
            h2, h6 {
                color: $white;
            }
        }
    }
}