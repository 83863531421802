.ce_rsce_angebotSlider {
    background-color: $background;
    padding: 4rem 2rem!important;
    .swiper-button-next, .swiper-button-prev {
        &::after {
            color: $primary;
        }
    }
    .swiper {
        .swiper-wrapper {
            .swiper-slide {
                li {
                    text-align: left;
                }
                .preis {
                    text-align: right;
                    padding-right: 2rem;
                    font-weight: bold;
                }
            }
        }
    }
}


@mixin rsce_angebotSlider_tablet {
    .ce_rsce_angebotSlider {
        padding: 2rem 10rem!important;
        .swiper {
            .swiper-wrapper {
                .swiper-slide {
                    img {
                        min-height: 200px;
                        width: 100%;
                        object-fit: cover;
                        aspect-ratio: 16/9;
                    }
                    h2 {
                        font-size: 1.5rem;
                        line-height: 1.5rem;
                    }
                }
            }
        }
    }
}
