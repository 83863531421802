.ce_rsce_trenner {
    padding: 2rem 0rem;
    .container {
        border-top: 2px solid $primary;
        border-bottom: 2px solid $primary;
        .container-inner {
            height: 450px;
            background-attachment: fixed;
            overflow: hidden;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}

@mixin rsce_trenner_tablet {
    .ce_rsce_trenner {
        padding: 4rem 0rem;
        .container {
            .container-inner {
                background-size: cover;
            }
        }
    }
}