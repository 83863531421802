[data-aos][data-aos-delay="50"],
[data-aos][data-aos-delay="100"],
[data-aos][data-aos-delay="150"],
[data-aos][data-aos-delay="200"],
[data-aos][data-aos-delay="250"],
[data-aos][data-aos-delay="300"],
[data-aos][data-aos-delay="350"],
[data-aos][data-aos-delay="400"],
[data-aos][data-aos-delay="450"],
[data-aos][data-aos-delay="500"],
[data-aos][data-aos-delay="550"],
[data-aos][data-aos-delay="600"],
[data-aos][data-aos-delay="650"],
[data-aos][data-aos-delay="700"],
[data-aos][data-aos-delay="750"],
[data-aos][data-aos-delay="800"],
[data-aos][data-aos-delay="850"],
[data-aos][data-aos-delay="900"],
[data-aos][data-aos-delay="950"],
[data-aos][data-aos-delay="1000"],
body[data-aos-delay="50"] [data-aos],
body[data-aos-delay="100"] [data-aos],
body[data-aos-delay="150"] [data-aos],
body[data-aos-delay="200"] [data-aos],
body[data-aos-delay="250"] [data-aos],
body[data-aos-delay="300"] [data-aos],
body[data-aos-delay="350"] [data-aos],
body[data-aos-delay="400"] [data-aos],
body[data-aos-delay="450"] [data-aos],
body[data-aos-delay="500"] [data-aos],
body[data-aos-delay="550"] [data-aos],
body[data-aos-delay="600"] [data-aos],
body[data-aos-delay="650"] [data-aos],
body[data-aos-delay="700"] [data-aos],
body[data-aos-delay="750"] [data-aos],
body[data-aos-delay="800"] [data-aos],
body[data-aos-delay="850"] [data-aos],
body[data-aos-delay="900"] [data-aos],
body[data-aos-delay="950"] [data-aos],
body[data-aos-delay="1000"] [data-aos] {
    transition-delay: 0;
}
[data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
    transition-duration: 50ms;
}
[data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
    transition-delay: 50ms;
}
[data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
    transition-duration: .1s;
}
[data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
    transition-delay: .1s;
}
[data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
    transition-duration: .15s;
}
[data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
    transition-delay: .15s;
}
[data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
    transition-duration: .2s;
}
[data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
    transition-delay: .2s;
}
[data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
    transition-duration: .25s;
}
[data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
    transition-delay: .25s;
}
[data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
    transition-duration: .3s;
}
[data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
    transition-delay: .3s;
}
[data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
    transition-duration: .35s;
}
[data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
    transition-delay: .35s;
}
[data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
    transition-duration: .4s;
}
[data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
    transition-delay: .4s;
}
[data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
    transition-duration: .45s;
}
[data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
    transition-delay: .45s;
}
[data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
    transition-duration: .5s;
}
[data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
    transition-delay: .5s;
}
[data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
    transition-duration: .55s;
}
[data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
    transition-delay: .55s;
}
[data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
    transition-duration: .6s;
}
[data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
    transition-delay: .6s;
}
[data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
    transition-duration: .65s;
}
[data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
    transition-delay: .65s;
}
[data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
    transition-duration: .7s;
}
[data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
    transition-delay: .7s;
}
[data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
    transition-duration: .75s;
}
[data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
    transition-delay: .75s;
}
[data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
    transition-duration: .8s;
}
[data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
    transition-delay: .8s;
}
[data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
    transition-duration: .85s;
}
[data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
    transition-delay: .85s;
}
[data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
    transition-duration: .9s;
}
[data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
    transition-delay: .9s;
}
[data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
    transition-duration: .95s;
}
[data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
    transition-delay: .95s;
}
[data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
    transition-duration: 1s;
}
[data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
    transition-delay: 1s;
}
[data-aos][data-aos-easing="ease"],
body[data-aos-easing="ease"] [data-aos] {
    transition-timing-function: ease;
}
[data-aos][data-aos-easing="ease-out-sine"],
body[data-aos-easing="ease-out-sine"] [data-aos] {
    transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

html.no-js{
	[data-aos^="fade"][data-aos^="fade"],
	[data-aos^="zoom"][data-aos^="zoom"] {
		opacity: 1;
	}
}

[data-aos^="fade"][data-aos^="fade"],
[data-aos^="zoom"][data-aos^="zoom"] {
    opacity: 0;
    transition-property: opacity, transform;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
    opacity: 1;
    transform: translateZ(0);
}

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
    opacity: 1;
    transform: translateZ(0) scale(1);
}

@media (prefers-reduced-motion: no-preference) {
	[data-aos="fade-up"] {
		transform: translate3d(0, 100px, 0);
	}
	[data-aos="fade-down"] {
		transform: translate3d(0, -100px, 0);
	}
	[data-aos="fade-right"] {
		transform: translate3d(-100px, 0, 0);
	}
	[data-aos="fade-left"] {
		transform: translate3d(100px, 0, 0);
	}
	[data-aos="fade-up-right"] {
		transform: translate3d(-100px, 100px, 0);
	}
	[data-aos="fade-up-left"] {
		transform: translate3d(100px, 100px, 0);
	}
	[data-aos="fade-down-right"] {
		transform: translate3d(-100px, -100px, 0);
	}
	[data-aos="fade-down-left"] {
		transform: translate3d(100px, -100px, 0);
	}
	[data-aos="zoom-in"] {
		transform: scale(.6);
	}
}

