.ce_rsce_wellnesspreise {
    padding: $paddingMobile;
    .container {
        &.preise {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 3rem;
            .angebot {
                flex: calc(33% - 3rem);
                width: calc(33% - 3rem);
                min-width: 300px;
                background-color: $background;
                transition: transform .5s;
                padding: 2rem;
                h3 {
                    font-size: 1.25rem;
                    line-height: 2rem;
                    hyphens: auto;
                    margin-top: 0;
                }
                .preis {
                    font-size: 1rem;
                    font-weight: bold;
                    text-align: end;
                }
                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}

@mixin rsce_wellnesspreise_tablet {
    .ce_rsce_wellnesspreise {
        padding: $paddingDesktop;
        .container {

        }
    }
}