.ce_rsce_gallerie {
    padding: $paddingMobile;
    .container {
        .container-inner {
            &.gallerie {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
                gap: 1rem;
                margin-top: 2rem;
                .gallerie-item {
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        aspect-ratio: 16/9;
                        transition: transform .5s;
                        &:hover {
                            transform: scale(1.1);
                            cursor: pointer;
                        }
                    }
                }
               
            }
        }
    }
}


@mixin rsce_gallerie_tablet {
    .ce_rsce_gallerie {
        padding: $paddingDesktop;
    }
}