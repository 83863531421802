.ce_rsce_footer {
    padding: $paddingMobile;
    background-color: $primary;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 500px;
        width: 100%;
        height: 350px;
        background-image: url('/files/themes/alte-post/images/kutsche_weiß.png');
        background-size: cover;
        background-repeat: no-repeat;
        opacity: .3;
    }
    .container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: .5rem;
        .container-inner {
            flex: 100%;
            h3 {
                font-size: 1.3rem;
                margin-bottom: 1rem;
                color: $white;
            }
            p {
                margin: 0;
                color: $white;
            }
            a {
                color: $white;
                transition: color .5s;
                &:hover {
                    color: pink;
                }
            }
            &.icons {
                h3 {
                    margin-bottom: .5rem;
                }
                .icon-list {
                    display: flex;
                    gap: 1rem;
                    .icon {
                        transition: transform .5s;
                        img {
                            width: 35px;
                            
                        }
                        &:hover {
                            transform: scale(1.15);
                        }
                    }
                }
            }
            &.logo {
                flex: 100%;
                min-width: 100%;
                display: flex;
                justify-content: center;
                padding: 1rem;
                a {
                    background-color: $white;
                    padding: 1rem;
                }
                img {
                    max-width: 10rem;
                    width: 100%;
                }
            }
        }
    }
}


@mixin rsce_footer_tablet {
    .ce_rsce_footer {
        padding: 2rem 6rem;
        .container {
            gap: 2rem;
            .container-inner {
                flex: calc(25% - 2rem);
                max-width: calc(25% - 2rem);
            }
        }
    }
}