/*! --Layout-- */
body {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 100vh;
	scroll-behavior: smooth;
}
footer {
	margin-top: auto;
}
img {
	display: block;
	max-width: 100%;
	height: auto;
	width: auto;
}

li::marker {
	color: $primary;
}

.button {
	display: block;
	text-align: center;
	padding: .5rem 1rem .5rem 1rem; 
	border: 1px solid $primary;
	background-color: transparent;
	color: $primary;
	transition: .5s;
	&:hover{
		background-color: $primary;
		color: $white;
		text-decoration: none; 
	}
}

iframe {
	padding: 2rem!important;
}