.ce_rsce_formular, .ce_form {
    background-color: $background;
    padding: $paddingMobile;
    .container {
        .container-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            h2 {
                text-align: center;
            }
            .ce_form {
                width: 100%;
                padding: unset;
            }
            .mod_mp_form_steps {
                ul {
                    display: flex;
                    justify-content: center;
                    gap: 2rem;
                    padding: 0;
                    li::marker {
                        color: transparent;
                    }
                    a,strong {
                        color: transparent;
                        display: block;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: $primary;
                    }
                    .accessible {
                        background-color: $primary;
                        border-radius: 50%;
                    }
                    .current {
                        background-color: $primary;
                        border-radius: 50%;
                    }
                }
            }
            .widget-text, .widget-textarea, .widget-select {
                display: flex;
                flex-direction: column;
                gap: .5rem;
                margin-bottom: 1rem;
                input, textarea, select {
                    padding: .5rem;
                    max-width: 100%;
                    min-width: 100%;
                }
            }
            .abstand {
                height: 50px;
            }
            .line {
                width: 100%;
                height: 1px;
                display: block;
                border-bottom: 1px solid gray;
            }
            .zusammenfassung {
                h3 {
                    color: $black;
                }
            }
            .widget-checkbox {
                flex: 100%;
                margin-top: 2rem;
                margin-bottom: 2rem;
                .checkbox_container {
                    input {
                        width: 30%;
                    }
                    input[type=radio], input[type=checkbox] {
                        display: none;
                    }
                    .style {
                        display: inline-block;
                        width: 20px;
                        min-width: 20px;
                        height: 20px;
                        background-color: $white;
                        cursor: pointer;
                        position: relative;
                        vertical-align: middle;
                        border: 1px solid $primary;
                    }
                
                    .style:after {
                        content: '';
                        position: absolute;
                        display: none;
                        left: 6px;
                        top: 4px;
                        width: 5px;
                        height: 10px;
                        border: solid #000;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }
                
                    input[type=radio]:checked + label:after, input[type=checkbox]:checked + label:after {
                        display: block;
                    }
    
                    span {
                        display: flex;
                        align-items: baseline;
                        max-width: unset;
                        width: 100%;
                        gap: 1rem;
    
                        p {
                            display: block;
                            margin: 0;
                        }
                        a {
                            color: $black;
                            text-decoration: underline;
                            &:hover {
                                color: $black;
                            }
                        }
                    }
    
                }
            }
            .widget-submit, .widget-pagebreak {
                display: flex;
                justify-content: end;
                &:has(button:nth-child(2)){
                    justify-content: space-between;
                    button:first-of-type {
                        order: 2;
                    }
                    button:last-of-type {
                        color: gray;
                        border: 1px solid transparent;
                        &:hover {
                            color: $primary;
                            background-color: transparent;
                        }
                    }
                }
                button {
                    display: block;
                    text-align: center;
                    padding: .5rem 1rem .5rem 1rem; 
                    border: 1px solid $primary;
                    background-color: transparent;
                    color: $primary;
                    transition: .5s;
                    min-width: 250px;
                    &:hover{
                        cursor: pointer;
                        background-color: $primary;
                        color: $white;
                        text-decoration: none; 
                    }
                }
            }
            
        }
    }
}

@mixin rsce_formular {
    .ce_rsce_formular, .ce_form {
        padding: $paddingDesktop;
        .container {
            .container-inner {
                .ce_form {
                    width: 80%;
                }
            }
        }
    }
}