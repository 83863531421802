.ce_rsce_counter {
    padding: $paddingMobile;
    max-width: 80rem;
    margin: 0 auto;
    .container {
        .container-inner {
            h2, h6 {
                text-align: center;
            }
            .icons {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 2rem;
                .icon {
                    flex: calc(25% - 2rem);
                    width: calc(25% - 2rem);
                    min-width: 130px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    img {
                        max-width: 60px;
                        margin-bottom: 1rem;
                    }
                    .heading {
                        font-weight: bold;
                        margin: 0;
                        text-align: center;
                    }
                    p {
                        margin: 0;
                        text-align: center;
                    }
                }
            }
        }
    }
}


@mixin rsce_counter_tablet {
    .ce_rsce_counter {
        padding: $paddingDesktop;
        .container {
            .container-inner {

            }
        }
    }
}