.ce_rsce_textBild {
    padding: $paddingMobile;
    .container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
        .container-inner {
            flex: calc(50% - 2rem);
            width: calc(50% - 2rem);
            min-width: 250px;
            .text-container {
                img {
                    width: fit-content;
                    height: unset;
                    object-fit: unset;
                    aspect-ratio: unset;
                }
            }
            img {
                width: 100%;
                object-fit: cover;
                aspect-ratio: 16/9;
            }
        }
    }
}



@mixin rsce_bildText_tablet {
    .ce_rsce_textBild {
        padding: $paddingDesktop;
        .container {
            gap: 4rem;
            .container-inner {
                flex: calc(50% - 4rem);
                width: calc(50% - 4rem);
                min-width: 340px;
                img {
                    height: 100%;
                }
            }
            &.right {
                .container-inner:first-of-type {
                    order: 2;
                }
                .container-inner:last-of-type {
                    order: 1;
                }
            }
        }
    }
}