.ce_rsce_quicklinks {
    padding: $paddingMobile;
    .container {
        .container-inner {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            .link {
                position: relative;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: transform .5s;
                }
                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                    &::after {
                        height: 100%;
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 3rem;
                    background-color: $primary;
                    opacity: .8;
                    transition: height .5s;
                }
                .text {
                    position: absolute;
                    bottom: .5rem;
                    left: 0;
                    width: 100%;
                    z-index: 1;
                    color: $white;
                    text-align: center;
                    p {
                        margin: 0;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}


@mixin rsce_quicklinks_tablet {
    .ce_rsce_quicklinks {
        padding: $paddingDesktop;
        .container {
            .container-inner {
                flex-direction: row;
                flex-wrap: wrap;
                .link {
                    flex: calc(25% - 2rem);
                    width: calc(25% - 2rem);
                }

            }
        }
    }
}