.ce_rsce_cta {
    padding: $paddingMobile;
    max-width: 80rem;
    margin: 0 auto;
    .container {
        .container-inner {
            background-color: $background;
            padding: 2rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            gap: 2rem;
            img {
                flex: calc(10% - 2rem);
                max-width: 70px;
            }
            .cta-text {
                flex: calc(600% - 2rem);
            }
            .cta-button {
                flex: calc(20% - 2rem);
            }
        }
    }
}

@mixin rsce_cta_tablet {
    .ce_rsce_cta {
        padding: $paddingDesktop;
        .container {
            .container-inner {
                gap: 5rem;
                img {
                    flex: calc(10% - 5rem);
                }
                .cta-text {
                    flex: calc(60% - 5rem);
                }
                .cta-button {
                    flex: calc(20% - 5rem);
                }
            }
        }
    }
}