.ce_rsce_infoBlock {
    padding: $paddingMobile;
    background-color: $background2;
    .container {
        .container-inner {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            .info {
                flex: calc(50% - 2rem);
                width: calc(50% - 2rem);
                min-width: 280px;
                background-color: $white;
                padding: 2rem;
                position: relative;
                margin-top: 4rem;
                h2 {
                    font-size: 1.25rem;
                    line-height: 2rem;
                    margin-top: 0;
                    text-align: center;
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: -4rem;
                    left: 50%;
                    width: 200px;
                    height: 63px;
                    transform: translateX(-50%);
                    background-image: url('/files/themes/alte-post/images/effect.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }
        }
    }
}


@mixin rsce_infoBlock_tablet {
    .ce_rsce_infoBlock {
        padding: $paddingDesktop;
        .container {
            .container-inner {
                gap: 5rem;
                .info {
                    flex: calc(50% - 5rem);
                    width: calc(50% - 5rem);
                    max-width: calc(50% - 5rem);
                }
            }
        }
    }
}