.ce_rsce_slider {
    background-color: $background;
    padding: 4rem 2rem!important;

    .swiper-button-next, .swiper-button-prev {
        &::after {
            color: $primary;
        }
    }
    .swiper {
        .swiper-wrapper {
            .swiper-slide {
                padding: 0rem 1rem;
            }
        }
    }
}


@mixin rsce_slider_tablet {
    .ce_rsce_slider {
        padding: 2rem 10rem!important;
        .swiper {
            padding: 0rem 4rem;
            .swiper-wrapper {
                .swiper-slide {
                    img {
                        height: 200px;
                        width: 100%;
                        object-fit: cover;
                    }
                    h2 {
                        font-size: 1.5rem;
                        line-height: 1.5rem;
                    }
                }
            }
        }
    }
}