.ce_rsce_gutschein {
    padding: $paddingMobile;
    background-color: $background;
    .container {
        .container-inner {
            img {
                height: 100%;
                object-fit: cover;
            }
        }
        &:last-of-type {
            display: flex;
            flex-direction: row;
            gap: 2rem;
            flex-wrap: wrap;
            .container-inner {
                flex: calc(50% - 2rem);
                width: calc(50% - 2rem);
                min-width: 250px;
                .ce_form {
                    width: 100%;
                    .formbody {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 1rem;
                    }
                    .widget-text, .widget-textarea, .widget-select {
                        display: flex;
                        flex-direction: column;
                        gap: .5rem;
        
                        input, textarea, select {
                            padding: .5rem;
                            max-width: 100%;
                            min-width: 100%;
                        }
                    }
                    .widget-text {
                        flex: calc(50% - 2rem);
                        width: calc(50% - 2rem);
                    }
                    .widget-textarea {
                        flex: 100%;
                        width: 100%;
                    }
                    .widget-select {
                        flex: 100%;
                        width: 100%;
                    }
                    .widget-checkbox {
                        flex: 100%;
                        margin-top: 2rem;
                        margin-bottom: 2rem;
                        .checkbox_container {
                            input {
                                width: 30%;
                            }
                            input[type=radio], input[type=checkbox] {
                                display: none;
                            }
                            .style {
                                display: inline-block;
                                width: 20px;
                                min-width: 20px;
                                height: 20px;
                                background-color: $white;
                                cursor: pointer;
                                position: relative;
                                vertical-align: middle;
                                border: 1px solid $primary;
                            }
                        
                            .style:after {
                                content: '';
                                position: absolute;
                                display: none;
                                left: 6px;
                                top: 4px;
                                width: 5px;
                                height: 10px;
                                border: solid #000;
                                border-width: 0 2px 2px 0;
                                transform: rotate(45deg);
                            }
                        
                            input[type=radio]:checked + label:after, input[type=checkbox]:checked + label:after {
                                display: block;
                            }
            
                            span {
                                display: flex;
                                align-items: baseline;
                                max-width: unset;
                                width: 100%;
                                gap: 1rem;
            
                                p {
                                    display: block;
                                    margin: 0;
                                }
                                a {
                                    color: $black;
                                    text-decoration: underline;
                                    &:hover {
                                        color: $black;
                                    }
                                }
                            }
            
                        }
                    }
                    .widget-submit {
                        display: flex;
                        justify-content: end;
                        flex: 100%;
                        button {
                            display: block;
                            text-align: center;
                            padding: .5rem 1rem .5rem 1rem; 
                            border: 1px solid $primary;
                            background-color: transparent;
                            color: $primary;
                            transition: .5s;
                            &:hover{
                                cursor: pointer;
                                background-color: $primary;
                                color: $white;
                                text-decoration: none; 
                            }
                        }
                    }
                }
            }
        }
    }
}

@mixin rsce_gutschein_tablet {
    .ce_rsce_gutschein {
        padding: $paddingDesktop;
        .container {
            .container-inner {

            }
        }
    }
}