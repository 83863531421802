.ce_rsce_zimmer {
    padding: $paddingMobile;
    
    .container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .container-inner {
            flex: 100%;
            &:last-of-type {
                position: relative;
                &::after {
                    display: none;
                    content: "";
                    position: absolute;
                    right: 0;
                    top: -350px;
                    max-width: 500px;
                    width: 100%;
                    height: 350px;
                    background-image: url('/files/themes/alte-post/images/kutsche.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                    opacity: .3;
                }
            }
            .zimmer {
                background-color: $background;
                padding: 2rem;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 1rem;
                margin-bottom: 4rem;
                .zimmer-inner {
                    flex: calc(25% - 2rem);
                    width: calc(25% - 2rem);
                    min-width: 250px;
                    img {
                        aspect-ratio: 16/9;
                        width: 100%;
                        object-fit: cover;
                    }
                    &:first-of-type {
                        flex: calc(35% - 2rem);
                        width: calc(35% - 2rem); 
                    }
                    &:nth-child(2n){
                        flex: calc(40% - 2rem);
                        width: calc(40% - 2rem);
                    }
                    &:nth-child(3n){
                        align-self: flex-end;
                        justify-content: end;
                    }
                    &:last-of-type {
                        align-self: flex-end;
                        justify-content: end;
                    }

                    img {
                        margin-bottom: 1rem;
                    }
                    h3 {
                        margin-top: .5rem;
                    }

                    .swiper-button-next, .swiper-button-prev {
                        &:after {
                            color: $primary;
                            padding: .5rem;
                            background-color: $white;
                        }
                    }


                    .detail {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        border-bottom: 1px solid gray;
                        flex-wrap: wrap;
                        .inner {
                            flex: calc(50%);
                            min-width: 200px;
                            p {
                                margin-top: .5rem;
                                margin-bottom: .5rem;
                                font-weight: bold;
                            }
                        }
                    }
                    .ausstattung {
                        color: $primary;
                        position: relative;
                        width: fit-content;
                        &::after {
                            content: "";
                            position: absolute;
                            top: calc(50% - 8px);                            
                            right: -1.5rem;
                            width: 16px;
                            height: 16px;
                            background-image: url('/files/themes/alte-post/images/open.png');
                            background-repeat: no-repeat;
                            background-size: cover;
                            transition: transform .5s;
                        }
                        &.active {
                            &::after {
                                transform: rotateZ(90deg);
                            }
                        }
                        &:hover {
                            cursor: pointer;
                        }

                    }
                    .beschreibung {
                        height: 0px;
                        opacity: 0;
                        transition: opacity .5s;
                        &.active {
                            height: 100%;
                            opacity: 1;
                        }
                        ul {
                            margin: 0 0 0 1rem;
                            padding-left: 0;
                        }

                    }
                    .preis {
                        text-align: end;
                        scroll-behavior: smooth;
                        p {
                            font-weight: bold;
                            font-size: 1.5rem;
                        }
                    }
                }

                .buttons {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    justify-content: end;
                    gap: 1rem;
                    .button {
                        min-width: 250px;
                    }
                }
            }
        }
    }
}


@mixin rsce_zimmer_tablet {
    .ce_rsce_zimmer {
        padding: $paddingDesktop;
        .container {
            .container-inner {
                &:last-of-type {
                    &::after {
                        display: block;
                    }
                }
                .zimmer {
                    gap: 2rem;
                }
            }
        }
    }
}