.ce_rsce_downloads {
    padding: $paddingMobile;
    .container {
        .container-inner {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            .link {
                flex: calc(33% - 2rem);
                width: calc(33% - 2rem);
                min-width: 250px;
                background-color: $background;
                &:hover {
                    text-decoration: none;
                }
                img {
                    width: 100%;
                }
                .text {
                    padding-left: 1rem;
                }
            }
        }
    }
}


@mixin rsce_download_tablet {
    .ce_rsce_downloads {
        padding: $paddingDesktop;
        .container {
            .container-inner {
                .link {
                    max-width: calc(33% - 2rem);
                }
            }
        }
    }
}