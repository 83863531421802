/*! --Breakpoints-- */
@include mq($mobile_s) {
    @include headlines_mobile;
}

@include mq($mobile) {
	
}

@include mq($mobile_l) {
	
}

@include mq($tablet_s) {
	
}

@include mq($tablet) {
    @include headlines_tablet;
    @include slideshowstart_tablet;
	@include rsce_header_tablet;
    @include rsce_footer_tablet;
    @include rsce_bildText_tablet;
    @include ce_text_tablet;
    @include rsce_angebotUebersicht_tablet;
    @include rsce_zimmer_tablet;
    @include rsce_wellnesspreise_tablet;
    @include rsce_trenner_tablet;
    @include rsce_quicklinks_tablet;
    @include rsce_gallerie_tablet;
    @include rsce_formular;
    @include rsce_gutschein_tablet;
    @include rsce_angebotSlider_tablet;
    @include rsce_slider_tablet;
    @include rsce_cta_tablet;
    @include rsce_textZweispaltig_tablet;
    @include rsce_download_tablet;
    @include rsce_infoBlock_tablet;
    @include rsce_counter_tablet;
}

@include mq($tablet_l) {
	
}

@include mq($desktop_s) {
	@include rsce_header_tablet_l;
}

@include mq($desktop) {
	@include headlines_desktop;
}

@include mq($desktop_l) {
    
}
